import React, { FC } from 'react';
import './ScopeOfProject.scss';
import SectionTitle from '../../../components/common/SectionTitle';
import Links from '../../../components/pagesComponents/CaseStudy/Links';
import Span from '../../../components/pagesComponents/CaseStudy/Span';
import SpectarLayout from '../../../components/PagesLayouts/SpectarLayout';
import { TLinkSp } from '../../../types/types';
import FixedButton from '../../../components/common/FixedButton';

const ScopeOfProject: FC = () => {
    const projectManagementLinks: TLinkSp = [
        { id: 0, title: 'Google Meet' },
        { id: 1, title: 'Slack' },
        { id: 2, title: 'Jira' },
        { id: 3, title: 'Confluence' },
        { id: 4, title: 'Microsoft 365' },
    ];

    const servicesLinks: TLinkSp = [
        { id: 0, title: 'Research', type: 'span' },
        { id: 1, title: 'Analytics', type: 'span' },
        { id: 2, title: 'Web development', type: 'span' },
        { id: 3, title: 'Cloud Services', type: 'span' },
        { id: 4, title: 'Quality control', margin: 'mb-15', type: 'span' },
        { id: 5, title: 'UX Design', type: 'paragraph' },
        { id: 6, title: 'UI Design', type: 'paragraph' },
    ];

    const backendLinks: TLinkSp = [
        { id: 0, title: 'Amazon AWS' },
        { id: 1, title: 'Java' },
        { id: 2, title: 'SpringBoot' },
        { id: 3, title: 'PostgreSQL' },
        { id: 4, title: 'Hibernate' },
        { id: 5, title: 'Axon' },
        { id: 6, title: 'Auth0 API' },
        { id: 7, title: 'Twilio API' },
    ];

    const frontendLinks: TLinkSp = [
        { id: 0, title: 'React' },
        { id: 1, title: 'Typescript' },
        { id: 2, title: 'Redux Thunk' },
        { id: 3, title: 'Redux Toolkit' },
        { id: 4, title: 'Apollo Graphql' },
    ];

    const otherTechnologiesLinks: TLinkSp = [
        { id: 0, title: 'Command Query' },
        { id: 1, title: 'Responsibility Segregation' },
        { id: 2, title: '(CQRS)', margin: 'mb-5' },
        { id: 3, title: 'Event-driven microservices' },
        { id: 4, title: ' Event sourcing', margin: 'mb-5' },
        { id: 5, title: ' RabbitMQ', margin: 'mb-5' },
        { id: 6, title: ' Flatfile.io', margin: 'mb-5' },
        { id: 7, title: 'Docker App' },
        { id: 8, title: 'Docker Hub' },
    ];

    const platformsLinks: TLinkSp = [
        { id: 0, title: 'Web', margin: 'mb-5', type: 'span' },
        { id: 1, title: 'Microsoft HoloLens', type: 'paragraph' },
        { id: 2, title: 'Microsoft HoloLens 2  ', margin: 'mb-25', type: 'paragraph' },
    ];

    return (
        <SpectarLayout>
            <div className="scope-of-project__wrapper">
                <SectionTitle type="primary" title="Scope of project" />
                <div className="scope-of-project__body">
                    <Span type="headline" subtype="mb-5">
                        Key results
                    </Span>
                    <Span type="paragraph" subtype="mb-30">
                        Web app was built within 6 months and 3–8 blackbirders involved via
                        on-demand team extension model.
                    </Span>
                    <Span type="headline" subtype="mb-5">
                        Front End
                    </Span>

                    <div className="scope-of-project__technologies scope-of-project__technologies--mb">
                        {frontendLinks.map((front) => (
                            <Span key={front.id} type="span" subtype="mr-15">
                                {front.title}
                            </Span>
                        ))}
                    </div>
                    <Span type="headline" subtype="mb-5">
                        Back End
                    </Span>
                    <div className="scope-of-project__technologies scope-of-project__technologies--mb">
                        {backendLinks.map((back) => (
                            <Span key={back.id} type="span" subtype="mr-15">
                                {back.title}
                            </Span>
                        ))}
                    </div>
                    <div className="scope-of-project__description">
                        <div className="scope-of-project__item">
                            <Span type="headline" subtype="mb-5">
                                Platforms
                            </Span>
                            {platformsLinks.map((span) => (
                                <Span key={span.id} type={span.type} subtype={span.margin}>
                                    {span.title}
                                </Span>
                            ))}

                            <Span type="headline" subtype="mb-5">
                                Services and expertise
                            </Span>
                            {servicesLinks.map((span) => (
                                <Span key={span.id} type={span.type} subtype={span.margin}>
                                    {span.title}
                                </Span>
                            ))}
                        </div>
                        <div className="scope-of-project__item">
                            <Span type="headline" subtype="mb-5">
                                Other technologies
                            </Span>
                            <Span type="span" subtype="mb-5">
                                Microservice architecture
                            </Span>
                            {otherTechnologiesLinks.map((tech) => (
                                <Span key={tech.id} type="paragraph" subtype={tech.margin}>
                                    {tech.title}
                                </Span>
                            ))}
                        </div>
                        <div className="scope-of-project__item">
                            <Span type="headline" subtype="mb-5">
                                Cooperation model
                            </Span>
                            <Span type="span" subtype="mb-15">
                                Fully managed IT services
                            </Span>
                            <Span type="headline" subtype="mb-5">
                                Project management
                            </Span>
                            {projectManagementLinks.map((span) => (
                                <Span key={span.id} type="paragraph">
                                    {span.title}
                                </Span>
                            ))}
                        </div>
                    </div>
                    <Links />
                </div>
            </div>
            <FixedButton />
        </SpectarLayout>
    );
};

export default ScopeOfProject;
